import {
  Button,
  Carousel,
  Dialog,
  Input,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import herbalHairOilImg1 from "../../../assets/images/herbal hair oil img1.jpg";
import herbalHairOilImg2 from "../../../assets/images/herbal hair oil img2.jpg";
import herbalHairOilImg3 from "../../../assets/images/herbal hair oil img3.jpg";
import herbalHairOilImg4 from "../../../assets/images/herbal hair oil img4.jpg";
import manPowerOilImg1 from "../../../assets/images/man power oil img1.jpg";
import manPowerOilImg2 from "../../../assets/images/man power oil img2.jpg";
import manPowerOilImg3 from "../../../assets/images/man power oil img3.jpg";
import manPowerOilImg4 from "../../../assets/images/man power oil img4.jpg";
import nightPowerImg1 from "../../../assets/images/night power img1.jpg";
import nightPowerImg2 from "../../../assets/images/night power img2.jpg";
import nightPowerImg3 from "../../../assets/images/night power img3.jpg";
import nightPowerImg4 from "../../../assets/images/night power img4.jpg";
import orthovedaImg1 from "../../../assets/images/orthoveda img1.jpg";
import orthovedaImg2 from "../../../assets/images/orthoveda img2.jpg";
import orthovedaImg3 from "../../../assets/images/orthoveda img3.jpg";
import orthovedaImg4 from "../../../assets/images/orthoveda img4.jpg";
import painOilImg1 from "../../../assets/images/pain oil img1.jpg";
import painOilImg2 from "../../../assets/images/pain oil img2.jpg";
import painOilImg3 from "../../../assets/images/pain oil img3.jpg";
import painOilImg4 from "../../../assets/images/pain oil img4.jpg";
import sugarPowderImg1 from "../../../assets/images/sugar powder img1.jpg";
import sugarPowderImg2 from "../../../assets/images/sugar powder img2.jpg";
import sugarPowderImg3 from "../../../assets/images/sugar powder img3.jpg";
import sugarPowderImg4 from "../../../assets/images/sugar powder img4.jpg";
import sugarVatiImg1 from "../../../assets/images/sugar vati img1.jpg";
import sugarVatiImg2 from "../../../assets/images/sugar vati img2.jpg";
import sugarVatiImg3 from "../../../assets/images/sugar vati img3.jpg";
import sugarVatiImg4 from "../../../assets/images/sugar vati img4.jpg";
import tulsiDropImg1 from "../../../assets/images/tulsi drop img1.jpg";
import tulsiDropImg2 from "../../../assets/images/tulsi drop img2.jpg";
import tulsiDropImg3 from "../../../assets/images/tulsi drop img3.jpg";
import pilesReliefImg1 from "../../../assets/images/piles img1.jpg";
import pilesReliefImg2 from "../../../assets/images/piles img2.jpg";
import pilesReliefImg3 from "../../../assets/images/piles img3.jpg";
import pilesReliefImg4 from "../../../assets/images/piles img4.jpg";
import spirulinaCapsuleImg1 from "../../../assets/images/spirulina img1.jpg";
import spirulinaCapsuleImg2 from "../../../assets/images/spirulina img2.jpg";
import spirulinaCapsuleImg3 from "../../../assets/images/spirulina img3.jpg";
import spirulinaCapsuleImg4 from "../../../assets/images/spirulina img4.jpg";
import appleCiderImg1 from "../../../assets/images/applecider img1.jpg";
import appleCiderImg2 from "../../../assets/images/applecider img2.jpg";
import appleCiderImg3 from "../../../assets/images/applecider img3.jpg";
import appleCiderImg4 from "../../../assets/images/applecider img4.jpg";
import seaBuckthornImg1 from "../../../assets/images/seabuckthorn img1.jpg";
import seaBuckthornImg2 from "../../../assets/images/seabuckthorn img2.jpg";
import seaBuckthornImg3 from "../../../assets/images/seabuckthorn img3.jpg";
import seaBuckthornImg4 from "../../../assets/images/seabuckthorn img4.jpg";
import antiImg1 from "../../../assets/images/anti img1.jpg";
import antiImg2 from "../../../assets/images/anti img2.jpg";
import antiImg3 from "../../../assets/images/anti img3.jpg";
import antiImg4 from "../../../assets/images/anti img4.jpg";
import { FaWhatsapp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import sendMail from "../../../apis/productEnquiry";
import WhatsAppLink from "../../../common/components/WhatsApp";

const medicines = [
  {
    id: 1,
    name: "Herbal Hair Oil",
    price: "₹1299/-",
    size: "100 ml",
    rating: 0,
    reviews: 0,
    img1: herbalHairOilImg1,
    img2: herbalHairOilImg2,
    img3: herbalHairOilImg3,
    img4: herbalHairOilImg4,
  },
  {
    id: 2,
    name: "Man Power Oil",
    price: "₹870/-",
    size: "10 ml",
    rating: 0,
    reviews: 0,
    img1: manPowerOilImg1,
    img2: manPowerOilImg2,
    img3: manPowerOilImg3,
    img4: manPowerOilImg4,
  },
  {
    id: 3,
    name: "Night Power Capsule",
    price: "₹1380/-",
    size: "30*500 mg",
    rating: 0,
    reviews: 0,
    img1: nightPowerImg1,
    img2: nightPowerImg2,
    img3: nightPowerImg3,
    img4: nightPowerImg4,
  },
  {
    id: 4,
    name: "Orthoveda",
    price: "₹1299/-",
    size: "60*500 mg",
    rating: 0,
    reviews: 0,
    img1: orthovedaImg1,
    img2: orthovedaImg2,
    img3: orthovedaImg3,
    img4: orthovedaImg4,
  },
  {
    id: 5,
    name: "Pain Oil",
    price: "₹299/-",
    size: "50 ml",
    rating: 0,
    reviews: 0,
    img1: painOilImg1,
    img2: painOilImg2,
    img3: painOilImg3,
    img4: painOilImg4,
  },
  {
    id: 6,
    name: "Sugar Powder",
    price: "₹1360/-",
    size: "100 g",
    rating: 0,
    reviews: 0,
    img1: sugarPowderImg1,
    img2: sugarPowderImg2,
    img3: sugarPowderImg3,
    img4: sugarPowderImg4,
  },
  {
    id: 7,
    name: "Sugar Vati",
    price: "₹1360/-",
    size: "30*500 mg",
    rating: 0,
    reviews: 0,
    img1: sugarVatiImg1,
    img2: sugarVatiImg2,
    img3: sugarVatiImg3,
    img4: sugarVatiImg4,
  },
  {
    id: 9,
    name: "Piles Relief Capsule",
    price: "₹1360/-",
    size: "60 capsule",
    rating: 0,
    reviews: 0,
    img1: pilesReliefImg1,
    img2: pilesReliefImg2,
    img3: pilesReliefImg3,
    img4: pilesReliefImg4,
  },
  {
    id: 10,
    name: "Spirulina Capsule",
    price: "₹1360/-",
    size: "30 capsules",
    rating: 0,
    reviews: 0,
    img1: spirulinaCapsuleImg1,
    img2: spirulinaCapsuleImg2,
    img3: spirulinaCapsuleImg3,
    img4: spirulinaCapsuleImg4,
  },
  {
    id: 11,
    name: "Apple Cider",
    price: "₹1199/-",
    size: "500ml",
    rating: 0,
    reviews: 0,
    img1: appleCiderImg1,
    img2: appleCiderImg2,
    img3: appleCiderImg3,
    img4: appleCiderImg4,
  },
  {
    id: 12,
    name: "Sea Buckthorn",
    price: "₹1199/-",
    size: "500ml",
    rating: 0,
    reviews: 0,
    img1: seaBuckthornImg1,
    img2: seaBuckthornImg2,
    img3: seaBuckthornImg3,
    img4: seaBuckthornImg4,
  },
  {
    id: 12,
    name: "Anti Addiction Drops",
    price: "₹1199/-",
    size: "50 ml",
    rating: 0,
    reviews: 0,
    img1: antiImg1,
    img2: antiImg2,
    img3: antiImg3,
    img4: antiImg4,
  },
  // Add more medicine items as needed
];

const MedicineCard = ({ medicine, onInquiryClick }) => (
  <div className="border rounded-lg shadow-md overflow-hidden">
    <img
      src={medicine?.img1}
      alt={medicine.name}
      className="w-full h-40 object-contain"
    />
    <div className="p-4">
      <Typography className="text-lg font-bold text-textLight-500 font-archivo">
        {medicine.name}
      </Typography>
      <div className="flex flex-row w-full justify-between items-center my-2">
        <Typography className="text-base font-medium text-textLight-400 font-archivo">
          {medicine.price}
        </Typography>
        <Typography className="text-base font-medium text-textLight-400 font-archivo">
          {medicine.size}
        </Typography>
      </div>
      {/* Rating stars here */}
      <Button
        variant="filled"
        color="green"
        size="sm"
        className="flex justify-center text-base font-normal items-center font-archivo bg-primaryLight-700 normal-case w-full"
        onClick={() => onInquiryClick(medicine)}>
        Raise Inquiry
      </Button>
    </div>
  </div>
);

const InquiryDialog = ({ isOpen, onClose, medicine }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const images = [
    medicine?.img1,
    medicine?.img2,
    medicine?.img3,
    medicine?.img4,
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");

    // console.log("Form Data:");
    // console.log("Name:", name);
    // console.log("Email:", email);
    // console.log("Phone Number:", phone);
    // console.log("Medicine Name:", medicine.name);
    // console.log("Medicine Price:", medicine.price);

    const body = {
      name,
      email,
      phone,
      pname: medicine.name,
      price: medicine.price,
    };

    const res = await sendMail(body, setLoading);
    if (res.success) {
      onClose();
    } else {
      setError(res.message);
    }

    // You can also send this data to a server here if needed
    // Close the dialog after submission
  };

  return (
    <Dialog open={isOpen} handler={onClose} size="xl">
      <div className="p-4 flex flex-col items-center w-full h-full">
        <div className="w-full flex items-end justify-end">
          <Button
            onClick={onClose}
            variant="text"
            color="red"
            className="p-0 m-0 mb-1"
            size="lg">
            <IoClose className="text-2xl" />
          </Button>
        </div>
        <div className="flex flex-col lg:flex-row md:flex-row items-start justify-evenly w-full h-full gap-4">
          <div className="w-full h-full">
            <Carousel
              autoplay={true}
              infiniteloop="true"
              interval={2000}
              showthumbs="false"
              showstatus="false"
              showindicators="true"
              className="w-full h-64 md:h-[50vh] lg:h-[50vh] mb-4 bg-black/10">
              {images?.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt={`Medicine Image ${index + 1}`}
                  className="w-full h-full object-contain"
                />
              ))}
            </Carousel>
          </div>
          <div className="w-full h-full flex flex-col gap-4">
            <Typography className="text-4xl font-semibold text-textLight-500 font-archivo">
              {medicine.name}
            </Typography>
            <Typography className="text-2xl font-semibold text-textLight-400 font-archivo">
              {medicine.price} - {medicine.size}
            </Typography>
            <div className="md:mt-6 lg:mt-6 mt-2 flex flex-col items-center w-full">
              <WhatsAppLink
                message={`Hii, I am interested in buying ${medicine.name}`}
              />
              <div className="flex items-center w-full my-4">
                <hr className="w-full border-gray-300" />
                <span className="px-4 text-gray-500 text-sm font-semibold">
                  or
                </span>
                <hr className="w-full border-gray-300" />
              </div>
              <form
                className="flex flex-col gap-4 w-full max-w-md"
                onSubmit={handleSubmit}>
                <Input
                  type="text"
                  label="Name"
                  name="name"
                  className="bg-white text-black"
                  required
                />
                <Input
                  type="email"
                  label="Email"
                  name="email"
                  className="bg-white text-black"
                  required
                />
                <Input
                  type="tel"
                  label="Phone Number"
                  name="phone"
                  className="bg-white text-black"
                  required
                />
                {error && (
                  <p className="text-sm text-red-500 text-center">{error}</p>
                )}
                <Button
                  type="submit"
                  className="mt-4 bg-green-600 text-white hover:bg-green-700 normal-case"
                  disabled={loading}>
                  {loading ? "Sending" : "Send"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const MedicineStore = () => {
  const [open, setOpen] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState(null);

  const handleInquiryClick = (medicine) => {
    setSelectedMedicine(medicine);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMedicine(null);
  };

  return (
    <div className="px-[4vw] py-[3vw]">
      <h1 className="text-2xl font-bold mb-4">Medicine Store</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {medicines.map((medicine) => (
          <MedicineCard
            key={medicine.id}
            medicine={medicine}
            onInquiryClick={handleInquiryClick}
          />
        ))}
      </div>
      <div className="w-full h-full flex justify-center items-center mt-[4vw] min-mt-4">
        <Button
          variant="filled"
          color="green"
          size="lg"
          className="flex justify-center text-base font-normal items-center font-archivo bg-primaryLight-700 normal-case w-fit">
          Load More
        </Button>
      </div>
      {selectedMedicine && (
        <InquiryDialog
          isOpen={open}
          onClose={handleClose}
          medicine={selectedMedicine}
        />
      )}
    </div>
  );
};

export default MedicineStore;
