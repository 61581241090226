import { Typography } from "@material-tailwind/react";
import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <>
      <div className="bg-[#fff] pt-6 mt-10 relative">
        {/* Curved Top */}
        {/* <div className="inset-0 bg-[#faf7f0] h-10 rounded-t-[200%] !border-2 !border-black w-full"></div> */}
        <div className="aajaBg"></div>

        <div className="text-center px-4 md:px-10  bg-[#f9f5e9]">
          {/* Address and Contact Information */}
          <Typography
            variant="small"
            className="text-gray-900 font-medium mb-2"
          >
            Delhi-Haridwar National Highway, Near Bahadrabad, Haridwar-249405,
            Uttarakhand, Bharat
          </Typography>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-6 text-gray-900">
            <div className="flex items-center space-x-2">
              <span role="img" aria-label="phone">
                📞
              </span>
              <Typography variant="small" className="font-medium">
              +91-9520205177
              </Typography>
            </div>
            <div className="flex items-center space-x-2">
              <span role="img" aria-label="email">
                📧
              </span>
              <Typography variant="small" className="font-medium">
              muktanamayurveda@gmail.com
              </Typography>
            </div>
          </div>

          {/* Footer Links */}
          <div className="flex flex-wrap justify-center space-x-4 my-4 pb-4 text-gray-900 text-sm">
            <a href="/terms-and-conditions" className="text-gray-900">
              Term & Condition
            </a>
            <span className="text-gray-900">|</span>
            <a href="/privacy-policy" className="text-gray-900">
              Privacy Policy
            </a>
            <span className="text-gray-900">|</span>
            <a href="/disclaimer" className="text-gray-900">
              Disclaimer
            </a>
            <span className="text-gray-900">|</span>
            <a href="/legal-policy" className="text-gray-900">
              Data Policy
            </a>
            {/* <span className="text-gray-900">|</span>
          <a href="/" className="text-gray-900">Daily Routine</a> */}
          </div>
        </div>
      </div>

      <div className="px-[4vw] py-1 border-1 border-black">
        <div className="flex justify-center items-center">
          <Typography className="flex flex-col sm:flex-col md:flex-col lg:flex-row justify-center items-center lg:text-lg md:text-lg sm::text-sm text-sm font-normal text-textLight-500 font-archivo gap-1">
            Copyright © 2024 Muktanam Ayurveda. Developed By{" "}
            <span className="font-semibold">Muktanam Parmagati Solutions Pvt. Ltd.</span>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Footer;
